import React, { useEffect, useState, ChangeEvent } from "react";
import "../style.css";
import {
  Button,
  Label,
  Field,
  Select,
  useId,
  Input,
  makeStyles,
  Spinner,
} from "@fluentui/react-components";
import moment from "moment";
import {
  ArrowUndo16Regular,
  ArrowRedo16Regular,
  Add16Filled,
  Edit24Filled,
  Delete16Regular,
  DismissFilled,
} from "@fluentui/react-icons";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { useStyles } from "./helper";
import TextEditor from "../../../components/TextEditor";
import ShowMoreText from "react-show-more-text";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setDialogModalOptions } from "../../../redux/modelDialogReducer";
import apiServices from "../../../service";
import VulnerabilityTable from "./VulnerabilityTable";
import FormikInputRequest from "../../../service/hook/useFormik";
import { formatedDate, formatedDate2, stripHtml } from "../../../utils";
import OverlayLoader from "../../../components/OverlayLoader";
import ReactQuillComponent from "../../../components/ReactQuillComponent";
import { setFormData, resetFormData } from "../../../redux/formUpdateReducer";
import ASCDropDown from "../../../components/DropDown";
import {
  useParams,
  useNavigate,
  useLocation,
  useMatch,
} from "react-router-dom";
import ContentLoader from "../../../components/Loader/ContentLoader";
import { FormLoader } from "../../../components/Loader/FormLoader";
import { replace } from "lodash";
import { useFormik } from "formik";
import DeletePopup from "../../../components/DeletePopup/Deletepopup";
import ASCButton from "../../../components/Button";
interface CharacteristicProps {
  [key: string]: any;
}
function VulnerabilityForm(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch("/clients/:clientId/vulnerability");
  let { context } = props;
  const classes = useStyles();
  const selectId = useId();
  const [formMode, setFormMode] = useState(props.mode === "add" ? "New" : "");
  // const [vulnerabilityData, setVulnerabilityData] = useState([]);
  const { clientId, action, selectedItemId } = useParams();
  const [dataList, setDataList] = useState<{ [key: string]: any }[]>([]);
  const dispatch: AppDispatch = useDispatch();
  const [mode, setMode] = useState(props.mode || "");
  const [loading, setLoading] = useState(false);
  const [nextAction, setNextAction] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [optionLoading, setOptionLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(1);
  const [selectedItem, setSelectedItem] = useState<any | {}>({});
  const [initialFormData, setInitialFormData] = useState({});
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteApiProcessing, setDeleteApiProcessing] = useState(false);
  const [selectedRowItem, setSelectedRowItem] = useState<{
    [key: string]: any;
  }>({});
  const isFormupdated = useSelector(
    (state: RootState) => state.formUpdated.isFormUpdated
  );
  const [past, setPast] = useState<any[]>([]);
  const [present, setPresent] = useState<{ [key: string]: any } | null>(null);
  const [future, setFuture] = useState<any[]>([]);
  // const [formupdated, setFormupdated] = useState(false);
  const onFormatDate = (date?: Date): string => {
    return !date ? "" : moment(date).format("DD-MM-YYYY");
  };
  const changeMode = (mode: string) => {
    setMode(mode);

    if (mode === "edit") {
      formikData.values = selectedRowItem;
    } else if (mode === "add") {
      formikData.resetForm();
    }
  };

  useEffect(() => {
    if (props.section != undefined) {
      if (!action) {
        if (dataList.length > 0) {
          if (props.module != undefined && props.module == "partner") {
            navigate(
              `/clients/${clientId}/partner/vulnerability/${dataList[0].vulnerability_id}/view`,
              {
                state: { selectedItem: dataList[0], section: "vulnerability" },
              }
            );
          } else {
            navigate(
              `/clients/${props.activeCustomerId}/vulnerability/${dataList[0].vulnerability_id}/view`,
              {
                state: { selectedItem: dataList[0], section: "vulnerability" },
              }
            );
          }
        }
      }
    }
    if (action != undefined) {
      if (action.length > 5) {
        if (props.module != undefined && props.module == "partner") {
          navigate(`/clients/${clientId}/partner/vulnerability/${action}/view`);
        } else {
          navigate(
            `/clients/${props.activeCustomerId}/vulnerability/${action}/view`
          );
        }
      } else {
        changeMode(action);
      }
    }
    if (selectedItemId != undefined) {
      if (dataList.length > 0) {
        const selectedRow = dataList.find(
          (item: any) => item.vulnerability_id === selectedItemId
        );
        if (selectedRow) {
          setSelectedRowItem(selectedRow);
        }
      }
    }
  }, [props.section, action, selectedItemId, dataList]);
  useEffect(() => {
    if (mode === "edit" && Object.keys(selectedRowItem).length !== 0) {
      fetchDriver();
      formikData.setValues(selectedRowItem);
    } else {
      if (mode === "add") {
        setCharacteristic([]);
      }
    }
  }, [mode, selectedRowItem]);
  useEffect(() => {
    if (Object.keys(selectedRowItem).length !== 0) {
      if (props.module != undefined && props.module == "partner") {
        navigate(
          `/clients/${clientId}/partner/vulnerability/${
            selectedRowItem.vulnerability_id
          }/${action ? action : "view"}`,
          {
            state: { selectedItem: selectedRowItem, section: "vulnerability" },
          }
        );
      } else {
        navigate(
          `/clients/${props.activeCustomerId}/vulnerability/${
            selectedRowItem.vulnerability_id
          }/${action ? action : "view"}`,
          {
            state: { selectedItem: selectedRowItem, section: "vulnerability" },
          }
        );
      }
    }
  }, [selectedRowItem]);

  useEffect(() => {
    fetchDriver();
  }, []);

  const formikData = useFormik({
    initialValues:
      action && ["edit", "view"].includes(action)
        ? selectedRowItem
        : {
            vulnerabilitydriver_id: "",
            vulnerabilitycharacteristic_id: "",
            note_text: "",
            ratings: "",
            vulnerability_date: null,
          },
    enableReinitialize: true,
    validate: (values) => {
      const errors: any = {};
      if (
        !values.vulnerabilitydriver_id ||
        values.vulnerabilitydriver_id === "0"
      ) {
        errors.vulnerabilitydriver_id = "Driver is required";
      }
      if (
        !values.vulnerabilitycharacteristic_id ||
        values.vulnerabilitydriver_id === "0"
      ) {
        errors.vulnerabilitycharacteristic_id = "Characteristics is required";
      }
      if (!values.note_text) {
        errors.note_text = "Note is required";
      } else if (values.note_text.length > 5000) {
        errors.note_text = "Note cannot be longer than 5000 characters";
      }
      const ratingValue = Number(values.ratings);
      if (isNaN(ratingValue)) {
        errors.ratings = "Ratings must be a number";
      } else if (ratingValue > 32767) {
        errors.ratings = "Score must not exceed 32767";
      }
      return errors;
    },
    onSubmit: async (values: any) => {
      if (mode === "add") {
        setIsLoading(true);
        try {
          let newData;
          newData = {
            vulnerability: {
              customer_id: props.activeCustomerId,
              vulnerabilitydriver_id: values.vulnerabilitydriver_id,
              ratings: values.ratings === "" ? 0 : Number(values.ratings) || 0,
              vulnerabilitycharacteristic_id:
                values.vulnerabilitycharacteristic_id,
              vulnerability_date:
                formatedDate2(values.vulnerability_date) || "",
              note_text: values.note_text,
            },
          };
          // setLoading(true);
          apiServices.client.vulnerability
            .create(newData)
            .then((response: any) => {
              // setLoading(false);
              setIsLoading(false);
              if (!response.data.isError) {
                props.notify(response.data.message, "", "success");
                dispatch(resetFormData());
                getvulnerability(props.activeCustomerId);
                if (props.module != undefined && props.module == "partner") {
                  navigate(`/clients/${clientId}/partner/vulnerability`);
                } else {
                  navigate(`/clients/${clientId}/vulnerability`);
                }
                // navigate(-1);
                // if (nextAction === "next") {
                //   props.changeTab?.(props.tabMenuIndex.next);
                // }
              } else {
                props.notify("Something went wrong", "", "error");
              }
            })
            .catch((error: Error) => {
              // setLoading(false);
              setIsLoading(false);
              props.notify("Something went wrong", "", "error");
              console.log("error");
            });
        } catch (error) {
          setIsLoading(false);
          props.notify("Something went wrong", "", "error");
          console.error("Error adding folder:", error);
        }
      } else if (mode === "edit") {
        setIsLoading(true);
        try {
          const newData = {
            vulnerability: {
              customer_id: props.activeCustomerId,
              vulnerabilitydriver_id: values.vulnerabilitydriver_id,
              ratings: values.ratings || 0,
              vulnerabilitycharacteristic_id:
                values.vulnerabilitycharacteristic_id,
              vulnerability_date:
                formatedDate2(values.vulnerability_date) || "",
              note_text: values.note_text,
            },
          };
          // setLoading(true);
          apiServices.client.vulnerability
            .update(selectedRowItem.vulnerability_id, newData)
            .then((response: any) => {
              setIsLoading(false);
              // setLoading(false);
              if (!response.data.isError) {
                props.notify(response.data.message, "", "success");

                dispatch(resetFormData());
                getvulnerability(props.activeCustomerId);
                if (props.module != undefined && props.module == "partner") {
                  navigate(`/clients/${clientId}/partner/vulnerability`);
                } else {
                  navigate(`/clients/${clientId}/vulnerability`);
                }
                // if (nextAction === "next") {
                //   props.changeTab?.(props.tabMenuIndex.next);
                // }
              } else {
                setIsLoading(false);
                props.notify("Something went wrong", "", "error");
              }
            })
            .catch((error: Error) => {
              // setLoading(false);
              setIsLoading(false);
              console.log("error");
            });
        } catch (error) {
          setIsLoading(false);
          console.error("Error adding document:", error);
        }
      }
    },
  });
  useEffect(() => {
    if (formikData.initialValues) {
      setPresent(formikData.initialValues);
    }
  }, [formikData.initialValues]);
  const undo = () => {
    if (past.length === 0) return;

    const previous = past[past.length - 1];
    setFuture((prev) => [present, ...prev]);
    setPast((prev) => prev.slice(0, -1));
    setPresent(previous);
    formikData.setValues(previous);

    // const newPast = [...past];

    // const newPresent = newPast.pop() || formikData.initialValues;

    // setPast(newPast);
    // setFuture([present, ...future]);
    // setPresent(newPresent);
    // formikData.setValues(newPresent);
  };
  const redo = () => {
    if (future.length === 0) return;

    const next = future[0];
    setPast((prev) => [...prev, present]);
    setFuture((prev) => prev.slice(1));
    setPresent(next);
    formikData.setValues(next);
    // const newFuture = [...future];
    // const newPresent = newFuture.shift();

    // setPast([...past, present]);
    // setFuture(newFuture);
    // setPresent(newPresent);
    // formikData.setValues(newPresent);
  };
  useEffect(() => {
    setCharacteristic([]);
    if (formikData.values.vulnerabilitydriver_id) {
      if (formikData.values.vulnerabilitydriver_id !== "0") {
        fetchCharacterstics(formikData.values.vulnerabilitydriver_id);
      } else {
        formikData.setFieldValue("vulnerabilitycharacteristic_id", 0);
      }
    }
  }, [formikData.values.vulnerabilitydriver_id]);

  function onChangeValue(key: string, value: any) {
    handleOnChange({
      target: { name: key, value: value },
    });
    formikData.setFieldValue(key, value);
  }

  const [driver, setDriver] = useState([{}]);
  const [characteristic, setCharacteristic] = useState<CharacteristicProps[]>(
    []
  );

  const fetchDriver = () => {
    setOptionLoading(true);
    apiServices.client.vulnerability
      .getDriver()
      .then((response: any) => {
        setOptionLoading(false);
        setDriver(response.data.data);
      })
      .catch((e: Error) => {
        setOptionLoading(false);
        console.log("error", e);
      });
  };
  const fetchCharacterstics = (id: string) => {
    setOptionLoading(true);
    apiServices.client.vulnerability
      .getCharacteristics(id)
      .then((response: any) => {
        setOptionLoading(false);
        const characteristics = response.data.data.data || [];
        setCharacteristic(characteristics);

        if (formikData.values.vulnerabilitydriver_id !== "" && mode === "add") {
          formikData.setFieldValue(
            "vulnerabilitycharacteristic_id",
            characteristics[0]?.vulnerabilitycharacteristic_id || ""
          );
        } else if (
          formikData.values.vulnerabilitydriver_id &&
          mode === "edit"
        ) {
          formikData.setFieldValue(
            "vulnerabilitycharacteristic_id",
            formikData.values.vulnerabilitycharacteristic_id || ""
          );
        } else {
          formikData.setFieldValue("vulnerabilitycharacteristic_id", "");
        }
      })
      .catch((e: Error) => {
        setOptionLoading(false);
        console.log("error", e);
      });
  };

  const handleRowAction = (action: string, row: any) => {
    changeMode(action);
    setSelectedRowItem(row);
  };

  const getvulnerability = (id: string) => {
    setLoading(true);
    apiServices.client.vulnerability
      .get(id)
      .then((response: any) => {
        setLoading(false);
        setDataList(response.data.data);
      })
      .catch((e: Error) => {
        setLoading(false);
        console.log("error", e);
      });
  };

  useEffect(() => {
    if (dataList.length > 0) {
      selectedIndex(0);
    } else {
      setMode("");
      setSelectedRowItem({});
    }
  }, [dataList]);

  // useEffect(() => {
  //   if (props.activeCustomerId && props.activeCustomerId !== null) {
  //     getvulnerability(props.activeCustomerId);
  //   }
  // }, [props.activeCustomerId]);
  useEffect(() => {
    if (match && match.params.clientId) {
      setMode("");
      getvulnerability(match.params.clientId);
    }
  }, [location, match]);

  const selectedIndex = (index: number) => {
    if (dataList.length > 0) {
      setMode("view");
      setSelectedRowItem(dataList[index]);
    }
  };
  async function fetchDriverOptions(): Promise<any[]> {
    try {
      const res = await apiServices.client.vulnerability.getDriver();

      if (res.data && res.data.data && res.data.data.length > 0) {
        const options = res.data.data.map((item: any) => ({
          label: item.driver,
          value: item.vulnerabilitydriver_id,
        }));

        return options;
      }

      return [];
    } catch (error) {
      console.error("Error fetching client type options:", error);

      return [];
    }
  }
  const updatePresent = (newState: any) => {
    // setPast([...past, present]);
    setPast((prev) => [...prev, present]);
    setPresent(newState);
    setFuture([]);
  };
  const customHandleChange = (event: any) => {
    handleOnChange(event);
  };
  const handleOnChange = (event: any) => {
    const data = { ...formikData.values };
    data[event.target.name] = event.target.value;
    let updated = false;
    // dispatch(setFormData());
    // if (["title", "first_name", "last_name"].includes(event.target.name)) {
    //   formik.setFieldValue("salutation", getSalutations(data));
    // }
    updatePresent(data);
    // if (isFormDataUpdated) {
    if (JSON.stringify(formikData.initialValues) === JSON.stringify(data)) {
      // // props.setFormUpdated(false);
      dispatch(resetFormData());
      updated = false;
    } else {
      // // props.setFormUpdated(true);
      dispatch(setFormData());
      updated = true;
    }
    // }

    if (mode !== "add" && updated === true) {
      setFormMode("Changed");
    } else {
      setFormMode(mode === "add" ? "New" : "");
    }
  };
  const deleteData = () => {
    setDeleteApiProcessing(true);
    apiServices.client.vulnerability
      .vulnerabilityDelete(selectedRowItem.vulnerability_id)
      .then((response: any) => {
        setDeleteApiProcessing(false);
        if (!response.data.isError) {
          setTimeout(() => {
            props.notify(response.data.message, "", "success");
            setDeletePopup(false);
            getvulnerability(props.activeCustomerId);
            if (props.module != undefined && props.module == "partner") {
              navigate(`/clients/${clientId}/partner/vulnerability`);
            } else {
              navigate(`/clients/${clientId}/vulnerability`);
            }
            // navigate(`/clients/${clientId}/${props.section}`, {
            //   replace: true,
            // });
          }, 1000);
        } else if (response.data.isError) {
          if (
            response.data.error &&
            response.data.error &&
            Object.keys(response.data.error).length !== 0
          ) {
            const errorMessage = Array.isArray(response.data.error.message)
              ? response.data.error.message.join(", ")
              : response.data.error.message;
            props.notify(errorMessage, "", "error");
          }
        } else {
          props.notify("Something went wrong", "", "error");
        }
      })
      .catch((err: any) => {
        props.notify(err.message, "", "error");
        setDeleteApiProcessing(false);
      });
    // setDeletePopup(false);
  };

  return loading ? (
    <ContentLoader />
  ) : (
    <>
      {(mode === "add" || mode === "edit") && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Label
                size="medium"
                weight="semibold"
                className={classes.message}
              >
                {formMode !== "" && (
                  <>
                    <span>**</span> {formMode} <span>**</span>
                  </>
                )}
              </Label>
            </div>
            <div>
              <ASCButton
                shape="rounded"
                icon={<ArrowUndo16Regular />}
                className={classes.btn_mode}
                onClick={undo}
                disabled={
                  past !== undefined && past.length === 0 ? true : false
                }
              />
              <ASCButton
                shape="rounded"
                icon={<ArrowRedo16Regular />}
                className={classes.btn_mode}
                onClick={redo}
                disabled={future.length === 0 ? true : false}
              />
            </div>
            <ASCButton
              shape="rounded"
              appearance="transparent"
              onClick={() => {
                setPast([]);
                setFuture([]);
                setPresent(formikData.initialValues);
                formikData.resetForm();
                dispatch(resetFormData());
              }}
              disabled={!isFormupdated}
              label="Reset All"
            />
          </div>

          <div className="form">
            <div className="grid-container">
              <Field label="Date">
                <div className="date_picker">
                  <DatePicker
                    style={{ width: "100%" }}
                    // placeholder="Date"
                    formatDate={onFormatDate}
                    name="vulnerability_date"
                    onSelectDate={(date) => {
                      onChangeValue("vulnerability_date", date);
                      if (customHandleChange !== undefined) {
                        customHandleChange({
                          target: { name: "vulnerability_date", value: date },
                        });
                      }
                      dispatch(setFormData());
                    }}
                    value={
                      formikData.values.vulnerability_date
                        ? mode === "add"
                          ? formikData.values.vulnerability_date
                          : mode === "edit"
                          ? new Date(formikData.values.vulnerability_date)
                          : null
                        : null
                    }
                  />
                  {formikData?.values.vulnerability_date ? (
                    <DismissFilled
                      onClick={() => {
                        formikData.setFieldValue("vulnerability_date", null);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </Field>
              <Field
                label={
                  <span>
                    Driver<span className="required-item">*</span>
                  </span>
                }
                validationState={
                  formikData.errors.vulnerabilitydriver_id ? "error" : "none"
                }
                validationMessage={`${
                  formikData?.errors?.vulnerabilitydriver_id || ""
                }`}
              >
                <Select
                  id={selectId}
                  placeholder="Select a driver"
                  value={formikData.values.vulnerabilitydriver_id}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    onChangeValue("vulnerabilitydriver_id", e.target.value);

                    dispatch(setFormData());
                  }}
                >
                  <option value={0}>{""}</option>
                  {driver &&
                    driver.map((optn: any) => (
                      <option
                        key={optn?.vulnerabilitydriver_id}
                        value={optn.vulnerabilitydriver_id}
                        selected={
                          formikData.values.vulnerabilitydriver_id ===
                          optn?.vulnerabilitydriver_id
                        }
                      >
                        {optn.driver}
                      </option>
                    ))}
                </Select>
              </Field>
              <Field
                label={
                  <span>
                    Characteristics<span className="required-item">*</span>
                  </span>
                }
                validationState={
                  formikData.errors.vulnerabilitycharacteristic_id
                    ? "error"
                    : "none"
                }
                validationMessage={`${
                  formikData?.errors?.vulnerabilitycharacteristic_id || ""
                }`}
              >
                <Select
                  id={selectId}
                  placeholder="Select a Characteristics"
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    onChangeValue(
                      "vulnerabilitycharacteristic_id",
                      e.target.value
                    );
                    dispatch(setFormData());
                  }}
                  value={formikData.values.vulnerabilitycharacteristic_id}
                >
                  {characteristic.length &&
                    characteristic.map((optn: any) => (
                      <option
                        key={optn?.vulnerabilitycharacteristic_id}
                        value={optn?.vulnerabilitycharacteristic_id}
                        selected={
                          formikData.values.vulnerabilitycharacteristic_id ===
                          optn?.vulnerabilitycharacteristic_id
                        }
                      >
                        {optn?.characteristics}
                      </option>
                    ))}
                </Select>
              </Field>
              <Field
                label={
                  <span>
                    Note<span className="required-item">*</span>
                  </span>
                }
                validationState={formikData.errors.note_text ? "error" : "none"}
                validationMessage={`${formikData?.errors?.note_text || ""}`}
              >
                <div>
                  <div style={{ backgroundColor: "#ffff" }}>
                    <TextEditor
                      name="note_text"
                      inputData={formikData.values.note_text}
                      onChange={(text: string) => {
                        const cleanedText = stripHtml(text).trim();
                        const sanitizedText =
                          /^(<p>\s*<br>\s*<\/p>|\s*)$/i.test(text.trim()) ||
                          cleanedText === ""
                            ? ""
                            : text;
                        onChangeValue("note_text", sanitizedText);
                        dispatch(setFormData());
                      }}
                    />
                  </div>
                  <span
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {formikData.values.note_text.length}/{5000}
                  </span>
                </div>
              </Field>
              <div style={{ alignSelf: "auto" }}>
                <Field
                  label="Score"
                  validationState={formikData.errors.ratings ? "error" : "none"}
                  validationMessage={`${formikData?.errors?.ratings || ""}`}
                >
                  <Input
                    // placeholder="ratings"
                    type="number"
                    name="ratings"
                    min="0"
                    value={formikData.values.ratings}
                    onChange={(ev: any) => {
                      onChangeValue("ratings", ev.target.value);
                      dispatch(setFormData());
                    }}
                  />
                </Field>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: 4,
              }}
            >
              <Button
                shape="rounded"
                type="submit"
                appearance="primary"
                className="asc-button-primary"
                onClick={() => {
                  if (isFormupdated) {
                    formikData.handleSubmit();
                  }
                }}
                disabled={
                  !isFormupdated || Object.keys(formikData.errors).length === 0
                    ? false
                    : true
                }
                icon={isLoading ? <Spinner size="tiny" /> : null}
              >
                {isLoading ? "Processing" : "Save"}
              </Button>

              {props.tabMenuIndex &&
                props.tabMenuIndex.next !== -1 &&
                !props.isContactHistory && (
                  <ASCButton
                    shape="rounded"
                    className={classes.btn_mode}
                    onClick={() => {
                      setNextAction("next");
                      isFormupdated
                        ? formikData.handleSubmit()
                        : setNextAction("next");
                    }}
                    disabled={
                      !isFormupdated ||
                      Object.keys(formikData.errors).length === 0
                        ? false
                        : true
                    }
                    label="Save & Next"
                  />
                )}
              <ASCButton
                shape="rounded"
                onClick={() => {
                  if (isFormupdated === true) {
                    dispatch(
                      setDialogModalOptions({
                        open: true,
                        content_line_1:
                          "We have detected an attempt to move away from the current page.",
                        content_line_2:
                          "This would cause all changes made to be lost",
                        cancel: {
                          onclick: () => {
                            setPast([]);
                            setFuture([]);
                            setPresent(formikData.initialValues);
                            dispatch(
                              setDialogModalOptions({
                                open: false,
                              })
                            );
                            // props.setFormUpdated(false);
                            dispatch(resetFormData());
                            changeMode("");
                            formikData.resetForm();
                            if (
                              props.module != undefined &&
                              props.module == "partner"
                            ) {
                              navigate(
                                `/clients/${clientId}/partner/vulnerability`
                              );
                            } else {
                              navigate(`/clients/${clientId}/vulnerability`);
                            }
                            // if (props.isContactHistory) {
                            //   dispatch(
                            //     setDialogModalOptions({ open: false })
                            //   );
                            // }
                          },
                          label: "Leave the Page",
                        },
                        no: {
                          onclick: () => {
                            dispatch(setDialogModalOptions({ open: false }));
                            // formikData.resetForm();
                          },
                          label: "Stay on Page",
                          type: "primary",
                        },
                      })
                    );
                  } else {
                    // dataList.length === 0 ? changeMode("") : changeMode("view");
                    // changeMode("");
                    setPast([]);
                    setFuture([]);
                    setPresent(formikData.initialValues);
                    formikData.resetForm();
                    navigate(-1);
                    // if (
                    //   props.module != undefined &&
                    //   props.module == "partner"
                    // ) {
                    //   alert(`/clients/${clientId}/partner/vulnerability`);
                    // } else {
                    //   alert(`/clients/${clientId}/vulnerability`);
                    // }
                  }
                }}
                label="Cancel"
              />
            </div>
          </div>
        </div>
      )}
      {mode === "view" && (
        <div>
          <div className="btn-wraper-container">
            <div>
              Record {currentRecord} of {dataList.length}
            </div>
            <div>
              <ASCButton
                shape="rounded"
                className="asc-button-primary"
                style={{ marginRight: 2, marginBottom: 6 }}
                appearance="primary"
                size="medium"
                icon={<Add16Filled />}
                onClick={() => {
                  if (props.module != undefined && props.module == "partner") {
                    navigate(
                      `/clients/${clientId}/partner/${props.section}/add`
                    );
                  } else {
                    navigate(`/clients/${clientId}/${props.section}/add`);
                  }

                  // changeMode("add");
                  // formikData.resetForm();
                }}
                label="Add New"
              />
              <ASCButton
                shape="rounded"
                style={{ marginRight: 2, marginBottom: 6 }}
                icon={<Edit24Filled />}
                size="medium"
                onClick={() => {
                  if (props.module != undefined && props.module == "partner") {
                    navigate(
                      `/clients/${clientId}/partner/${props.section}/${selectedRowItem.vulnerability_id}/edit`
                    );
                  } else {
                    navigate(
                      `/clients/${clientId}/${props.section}/${selectedRowItem.vulnerability_id}/edit`
                    );
                  }
                }}
                label="Edit"
              />
              <ASCButton
                shape="rounded"
                icon={<Delete16Regular />}
                style={{ marginRight: 2, marginBottom: 6, color: "red" }}
                size="medium"
                onClick={() => {
                  setDeletePopup(true);
                }}
                label="Delete"
              />
            </div>
          </div>
          <div className="form">
            <div className="grid-container">
              <div className="fieldWrapper">
                <Label weight="semibold" size="medium">
                  Date
                </Label>
                <Label>
                  {formatedDate(selectedRowItem?.vulnerability_date)}
                </Label>
              </div>
              <div className="fieldWrapper">
                <Label weight="semibold" size="medium">
                  Driver
                </Label>
                <Label>{selectedRowItem?.driver}</Label>
              </div>
              <div className="fieldWrapper">
                <Label weight="semibold" size="medium">
                  Characteristics
                </Label>
                <Label>{selectedRowItem?.characteristics}</Label>
              </div>
              <div className="fieldWrapper">
                <Label weight="semibold" size="medium">
                  Note
                </Label>

                <ReactQuillComponent value={selectedRowItem?.note_text} />
              </div>
              <div className="fieldWrapper">
                <Label weight="semibold" size="medium">
                  Score
                </Label>
                <Label> {selectedRowItem?.ratings}</Label>
              </div>
            </div>
          </div>
        </div>
      )}
      {dataList && dataList.length > 0 && ["", "view"].includes(mode) ? (
        <VulnerabilityTable
          {...{
            changeMode,
            handleRowAction,
            mode,
            context: props.context,
            data: dataList,
            selectedRowItem,
            setCurrentRecord,
            tableId: props.tableId,
            selectedItem: selectedItem,
            setSelectedItem: setSelectedItem,
            section: props.section,
            selectedItemId,
            module: props.module,
          }}
        />
      ) : (
        <div>
          {mode === "" && (
            <>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                  alignItems: "flex-start",
                }}
              >
                <ASCButton
                  shape="rounded"
                  className="asc-button-primary"
                  style={{ marginRight: 2, marginBottom: 6 }}
                  appearance="primary"
                  size="medium"
                  icon={<Add16Filled />}
                  onClick={() => {
                    if (
                      props.module != undefined &&
                      props.module == "partner"
                    ) {
                      navigate(
                        `/clients/${clientId}/partner/${props.section}/add`
                      );
                    } else {
                      navigate(`/clients/${clientId}/${props.section}/add`);
                    }

                    // changeMode("add");
                    // formikData.resetForm();
                  }}
                  label="Add New"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 200,
                }}
              >
                <Label size="large" style={{ color: "red" }}>
                  There are no active records - click Add New to create a new
                  record
                </Label>
              </div>
            </>
          )}
        </div>
      )}
      <DeletePopup
        deletePopup={deletePopup}
        setDeletePopup={setDeletePopup}
        deleteData={deleteData}
        deleteApiProcessing={deleteApiProcessing}
      />
    </>
  );
}

export default VulnerabilityForm;
