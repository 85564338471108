import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";

import CaseNavList from "../CaseNavList";
import CaseForm from "../CaseForm";
import CaseList from "../CaseList";
import ContentLoader from "../../../../components/Loader/ContentLoader";
import ModalDialog from "../../../../components/ModalDialog";
import { getCaseCategoryByCaseType } from "../../../../utils";
import { Label, Toaster } from "@fluentui/react-components";
import { makeStyles, tokens } from "@fluentui/react-components";
import CaseDetailDrawer from "../CaseDetailDrawer";
import {
  getPolicyCommissionDistributionDetailById,
  getPolicyCommissionDistributionListItems,
  getPolicyDetailById,
  getPolicyPaymentDetailById,
  getPolicyPaymentsCommissionDetailById,
  getPolicyPaymentsCommissionsListItems,
} from "../../../../redux/Case/policySlice";

const useStyles = makeStyles({
  emptyState: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
  },
});

function PolicyCommissionDistribution({ tab }: { tab?: string }) {
  const styles = useStyles();
  const navigate = useNavigate();
  const { clientId, action, caseId, subItemId, subSubItemId, distributionId } =
    useParams();
  const dispatch: AppDispatch = useDispatch();
  const policies = useSelector((state: RootState) => state.policies);
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const [caseType, setCaseType] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>(tab || "distribution");
  const [isOpen, setIsOpen] = useState(false);
  const handleDetailPopup = (action: boolean) => {
    setIsOpen(action);
  };
  useEffect(() => {
    if (caseId && clientId && subItemId && subSubItemId) {
      dispatch(getPolicyDetailById({ id: caseId, customerId: clientId }));
      dispatch(getPolicyPaymentDetailById(subItemId));
      dispatch(
        getPolicyPaymentsCommissionsListItems({
          paymentId: subItemId,
          caseId,
          customerId: clientId,
        })
      );
      dispatch(
        getPolicyCommissionDistributionListItems({
          commissionId: subSubItemId,
        })
      );
    }
  }, [caseId, clientId, subItemId, subSubItemId, dispatch]);

  useEffect(() => {
    if (policies?.activeItem?.case_type) {
      setCaseType(getCaseCategoryByCaseType(policies.activeItem.case_type));
    }
  }, [policies?.activeItem]);

  useEffect(() => {
    if (subItemId) {
      dispatch(getPolicyPaymentDetailById(subItemId));
    }
  }, [subItemId, activeTab]);

  useEffect(() => {
    if (subSubItemId) {
      dispatch(getPolicyPaymentsCommissionDetailById(subSubItemId));
    }
  }, [subSubItemId, activeTab]);

  useEffect(() => {
    if (distributionId) {
      dispatch(getPolicyCommissionDistributionDetailById(distributionId));
    }
  }, [subSubItemId, distributionId, activeTab]);

  useEffect(() => {
    if (
      action &&
      action != "add" &&
      policies?.payments.commissions.distribution > 0 &&
      subSubItemId
    ) {
      navigate(
        `/clients/${clientId}/policy/${caseId}/payments/${subItemId}/commission/${subSubItemId}/${
          tab || "distribution"
        }/${
          distributionId ||
          policies?.payments.commissions.distribution.listItems[0]
            .commission_allocation_id
        }/distribution/${action || "view"}`
      );
    }
  }, [
    policies?.payments.commissions.distribution.listItems,
    subItemId,
    subSubItemId,
    action,
    clientId,
    caseId,
    tab,
    navigate,
  ]);

  return (
    <div>
      <CaseNavList
        subItem="payments"
        section="policies"
        tab={activeTab}
        caseType={caseType}
        caseDetail={policies?.payments.activeItem}
        handleDetailPopup={handleDetailPopup}
      />
      <div className="case_container_flex">
        <CaseForm
          mode={action || ""}
          section="policies"
          caseType={caseType}
          caseDetail={policies?.payments.commissions.distribution.activeItem}
          caseItemList={policies?.payments.commissions.distribution.listItems}
          tab={activeTab}
          subItem="payments"
        />
        <div>
          {policies?.payments.commissions.distribution.loading ? (
            <ContentLoader />
          ) : (
            <>
              {policies?.payments.commissions.distribution.listItems.length >
                0 && (
                <CaseList
                  module="distribution"
                  data={policies?.payments.commissions.distribution.listItems}
                  activeItem={
                    policies?.payments.commissions.distribution.activeItem
                  }
                  section="policies"
                  activeTab={tab}
                />
              )}
              {!action &&
                policies?.payments.commissions.distribution.listItems.length ===
                  0 && (
                  <div className={styles.emptyState}>
                    <Label size="large" style={{ color: "red" }}>
                      There are no active records - click Add New to create a
                      new record
                    </Label>
                  </div>
                )}
            </>
          )}
        </div>
      </div>
      <CaseDetailDrawer
        isOpen={isOpen}
        caseDetail={policies?.activeItem}
        handleDetailPopup={handleDetailPopup}
      />
      <Toaster position="top-end" timeout={5000} />
      <ModalDialog options={dialogModalOptions} />
    </div>
  );
}

export default PolicyCommissionDistribution;
