import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import CaseNavList from "../CaseNavList";
import CaseForm from "../CaseForm";
import CaseList from "../CaseList";
import ContentLoader from "../../../../components/Loader/ContentLoader";
import ModalDialog from "../../../../components/ModalDialog";
import { getCaseCategoryByCaseType } from "../../../../utils";
import { Label, Toaster } from "@fluentui/react-components";
import { makeStyles, tokens } from "@fluentui/react-components";
import CaseDetailDrawer from "../CaseDetailDrawer";
import {
  getLiabilityDetailById,
  getLiabilityPaymentDetailById,
  getLiabilityPaymentsCommissionDetailById,
  getLiabilityPaymentsCommissionsListItems,
} from "../../../../redux/Case/liabilitySlice";

const useStyles = makeStyles({
  emptyState: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
  },
});

function LiabilityPaymentCommission({ tab }: { tab?: string }) {
  const styles = useStyles();
  const navigate = useNavigate();
  const { clientId, action, caseId, subItemId, subSubItemId } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const liabilities = useSelector((state: RootState) => state.liabilities);
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const [caseType, setCaseType] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>(tab || "commission");
  const [isOpen, setIsOpen] = useState(false);
  const handleDetailPopup = (action: boolean) => {
    setIsOpen(action);
  };
  useEffect(() => {
    if (caseId && clientId && subItemId) {
      dispatch(getLiabilityDetailById({ id: caseId, customerId: clientId }));
      dispatch(getLiabilityPaymentDetailById(subItemId));
      dispatch(
        getLiabilityPaymentsCommissionsListItems({
          paymentId: subItemId,
          caseId,
          customerId: clientId,
        })
      );
    }
  }, [caseId, clientId, subItemId, dispatch]);

  useEffect(() => {
    if (liabilities?.activeItem?.case_type) {
      setCaseType(getCaseCategoryByCaseType(liabilities.activeItem.case_type));
    }
  }, [liabilities.activeItem]);

  useEffect(() => {
    if (subItemId) {
      dispatch(getLiabilityPaymentDetailById(subItemId));
    }
  }, [subItemId, activeTab]);

  useEffect(() => {
    if (subSubItemId) {
      dispatch(getLiabilityPaymentsCommissionDetailById(subSubItemId));
    }
  }, [subSubItemId, activeTab]);

  useEffect(() => {
    if (
      action &&
      action != "add" &&
      liabilities.payments.commissions.listItems.length > 0 &&
      subItemId
    ) {
      navigate(
        `/clients/${clientId}/liabilities/${caseId}/payments/${subItemId}/${
          tab || "commission"
        }/${
          subSubItemId ||
          liabilities.payments.commissions.listItems[0].commission_id
        }/commission/${action || "view"}`
      );
    }
  }, [
    liabilities.payments.commissions.listItems,
    subItemId,
    subSubItemId,
    action,
    clientId,
    caseId,
    tab,
    navigate,
  ]);

  return (
    <div>
      <CaseNavList
        subItem="payments"
        section="liabilities"
        tab={activeTab}
        caseType={caseType}
        caseDetail={liabilities.payments.activeItem}
        handleDetailPopup={handleDetailPopup}
      />
      <div className="case_container_flex">
        <CaseForm
          mode={action || ""}
          section="liabilities"
          caseType={caseType}
          caseDetail={liabilities.payments.commissions.activeItem}
          caseItemList={liabilities.payments.commissions.listItems}
          tab={activeTab}
          subItem="payments"
        />

        <div>
          {liabilities?.payments.commissions.loading ? (
            <ContentLoader />
          ) : (
            <>
              {liabilities?.payments.commissions.listItems.length > 0 && (
                <CaseList
                  module="commission"
                  data={liabilities?.payments.commissions.listItems}
                  activeItem={liabilities?.payments.commissions.activeItem}
                  section="liabilities"
                  activeTab={tab}
                />
              )}
              {!action &&
                liabilities?.payments.commissions.listItems.length === 0 && (
                  <div className={styles.emptyState}>
                    <Label size="large" style={{ color: "red" }}>
                      There are no active records - click Add New to create a
                      new record
                    </Label>
                  </div>
                )}
            </>
          )}
        </div>
      </div>
      <CaseDetailDrawer
        isOpen={isOpen}
        caseDetail={liabilities?.activeItem}
        handleDetailPopup={handleDetailPopup}
      />
      <Toaster position="top-end" timeout={5000} />
      <ModalDialog options={dialogModalOptions} />
    </div>
  );
}

export default LiabilityPaymentCommission;
